
.admin-page {
    padding-top: $spacing !important;
    max-width: $size-desktop * 1.05;
    margin: 0 auto;

    label{
        font-size: $font-largish !important;
      
    }
    input{
        padding: $spacing;
        font-size: $font-normal;;
    }
    .react-select__value-container{
        padding: 0 $spacing !important;
    }
    .field-row{
        padding-bottom: $spacing;
    }
    .admin-upload-image {
        max-width: $padding * 10;
    }

    .page-subtitle {
        margin-left: 0;
        font-weight: 900;
        font-size: $font-largest * 1.25;
        color: $dark4;
    }

    .advert-logo {
        max-width: $padding * 3;
    }

    .company-analytics {
        @include box-theme();
        line-height: 1 !important;
        padding: $padding * 2;
        background: transparent;

        * {
            background: transparent;
            line-height: 1 !important;
        }

        ul {
            li {}
        }
    }
}