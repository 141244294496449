.react-mde-wrapper {
    .rc-md-editor {
        @include box-theme();
        padding: 0;
        @include border-radius($rad);

        .section {
            padding-top: 0
        }

        .editor-container {
            color: $dark5 !important;

            textarea {
                font-size: $font-large !important;
            }
        }

        .custom-html-style{
            h1, h2, h3, h4, h5, h6, p{
                margin: 0;
                padding: 0;
                padding-bottom: $padding;
                font-weight: 900;
            }

            img{
                max-width: 250px;
            }
            
            p{
                font-weight: 400;
                font-size: $font-large * 1.15 !important;
            }
        }

        &.full {
            z-index: 100000000;
        }
    }
}