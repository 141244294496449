.login {

    //padding: $padding * 5 0;
   // margin: $padding * 5 auto;
    max-width: $padding * 32;
    padding: $padding * 5 0 !important;

    .login-form {
        @include box-theme();
        @include box-shadow-lg();
        padding: $padding * 3.5 !important;
        text-align: center;
        margin-bottom: $padding * 2;

        .error{
            display: none;
        }

        h1 {
            font-size: $font-huge * 0.9;
            line-height: 1;
            margin-bottom: $spacing;
        }

        h3 {
            margin-bottom: $padding * 2;
            line-height: 1.35;
            color: $dark2;
        }

        .field-row{
            padding: 0;
            margin-bottom: 0;


        }

        .btn{
            font-size: $font-largest * 1.1;
            padding: $padding * 1.5 $padding * 2;
        }

        .login-login{
            font-size: $font-large;
            padding-top: $padding * 1.5;
        }
    }


}