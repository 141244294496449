@mixin btn-theme() {
  @include border-radius($rad);
  padding-top: 0;
  padding-bottom: 0;
  line-height: 100%;
  font-weight: 700;
  background: transparent;
  vertical-align: middle; // Default size
  font-size: unset;
  border: none !important;
  padding: 0 $padding;
  @include transition-fade(170ms);

  &:hover,
  &:active {
    cursor: pointer;
    //@include box-shadow();
  }
}

@mixin box-theme() {
  // background: lighten($light2, 2);
  // @include box-shadow();
  border: none;
  @include border-radius($spacing * 1.5);
  overflow-x: hidden;
  padding: $padding;
  border: solid 1px darken($light2, 3);

  //@include transition-fade(180ms);

  &.padded {
    $padding: $padding +$spacing;
  }

  @media (max-width: $size-mobile) {
    padding: $padding * 1.5 $padding;
    margin-bottom: $padding;


  }

  &.show-hover {
    &:hover {
      border-color: darken($light2, 5);
      background: lighten($light2, 4);
    }
  }

  &.success {
    border-color: lighten($green1, 4);
    // background: lighten($light2, 4);


    &:hover {
      border-color: lighten($green1, 10);
      border-left: solid 1 lighten($green2, 10);
      background: darken($light1, 2);
    }
  }
}

@mixin basic_input() {
  color: $dark5;
  background: white;
  border: solid 1px darken($light4, 3);
  @include border-radius($rad);
  width: 100%;
  box-shadow: none;
  outline: none;
  text-align: left;
  text-shadow: none;
  padding: $padding * 0.75 $spacing;
  font-size: $font-normal * 1.25;
  //height: $control-height;1
  line-height: 1.25;


  &:hover {
    -webkit-box-shadow: 0px 0px 10px 1px rgba(100, 100, 120, 0.2);
    -moz-box-shadow: 0px 0px 10px 1px rgba(100, 100, 120, 0.2);
    box-shadow: 0px 0px 10px 1px rgba(100, 100, 120, 0.2);
  }

  &:active,
  &:focus {
    -webkit-box-shadow: 0px 0px 5px 1px rgba(100, 100, 120, 0.4);
    -moz-box-shadow: 0px 0px 5px 1px rgba(100, 100, 120, 0.4);
    box-shadow: 0px 0px 5px 1px rgba(100, 100, 120, 0.4);
    border: solid 1px $light3;

  }

  &::placeholder {
    /* WebKit, Blink, Edge */
    color: lighten($dark1, 20);
  }

}



@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin transition-fade($duration) {
  $affects: opacity $duration ease-out,
  border $duration ease-out,
  background $duration ease-out,
  color $duration ease-out;
  -webkit-transition: $affects;
  -moz-transition: $affects;
  -ms-transition: $affects;
  -o-transition: $affects;
  transition: $affects;
}


@mixin box-shadow-lg() {
  -webkit-box-shadow: 0px 0px 18px 1px rgba(180, 190, 200, 0.3);
  -moz-box-shadow: 0px 0px 18px 1px rgba(180, 190, 200, 0.3);
  box-shadow: 0px 0px 18px 1px rgba(180, 180, 200, 0.30);
}


@mixin box-shadow() {
  -webkit-box-shadow: 0px 0px 9px 1px rgba(180, 180, 190, 0.45);
  -moz-box-shadow: 0px 0px 9px 1px rgba(180, 180, 190, 0.45);
  box-shadow: 0px 0px 9px 1px rgba(180, 180, 190, 0.45);
}

@mixin box-shadow-light() {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(220, 220, 240, 0.4);
  -moz-box-shadow: 0px 0px 5px 1px rgba(220, 220, 240, 0.4);
  box-shadow: 0px 0px 5px 1px rgba(220, 220, 240, 0.4);
}


@mixin box-shadow-dark() {
  -webkit-box-shadow: 0px 0px 15px 2px rgba(180, 180, 200, 0.6);
  -moz-box-shadow: 0px 0px 15px 2px rgba(180, 180, 200, 0.6);
  box-shadow: 0px 0px 15px 2px rgba(180, 180, 200, 0.6);
}

@mixin box-shadow-success() {
  -webkit-box-shadow: 0px 0px 35px 2px rgba(160, 220, 210, 0.4);
  -moz-box-shadow: 0px 0px 35px 2px rgba(160, 220, 210, 0.4);
  box-shadow: 0px 0px 25px 3px rgba(160, 220, 210, 0.4);
}


@mixin ellipsis() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1;
}


@mixin inner-box-shadow() {
  box-shadow: inset 1px 1px 15px -2px rgba(220, 220, 240, 0.4);
}