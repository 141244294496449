$base-unit: 16px;

$blue1: #002fff;
$blue2: #0B5Bb0;// darken($blue1, 15);


/* Dark */
$dark5: #070809;

$dark1: lighten($dark5, 30);
$dark2: lighten($dark5, 26);
$dark3: lighten($dark5, 8);
$dark4:lighten($dark5, 4);

/* Light */

$primary: #EF2D56;

$light1: #ffffff;//fcfdfe;
$light2: darken(#fcfdfe, 9);
$light3: darken(#fcfdfe, 18);
$light4: darken(#fcfdfe, 29);

/* Green */

$green1: #007905;
$green2: #007303;

/* Red */
$red1: #d22f2a;
$red2: #c91611;

$pink: #E21752;

/* Misc */
$gold: #FFD700;
$orange1: #FF9B71;
$orange2: #FF9B71;

/* Fonts */


$font-huge: 4.4rem;;
$font-largest: 2.1rem;
$font-large:	1.8rem;
$font-largish:  1.6rem;
$font-normal: 1.5rem;
$font-small: 1.4rem;
$font-tiny: 1.3rem;


/* Padding */
/* Padding */

$padding: $base-unit;
$spacing: $padding / 2;
$rad: $spacing / 2;

// Widths

$size-mobile: 767px;
$size-tablet: 767px;
$size-desktop: 1100px;
$size-widescreen: 1255px;

$menu-width: 300px;

// Heights
$control-height: $padding * 2.5;

