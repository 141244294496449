

$mobile-row: $padding * 2.4;
$desktop-row: $padding * 3;




.ReactTable {
  @include box-theme();
  padding: 0 !important;
  overflow: hidden;
  background: darken($light2, 3);
  border: solid 1px $light3;


  @media (max-width: $size-mobile) {
    border: none;

  }



// Input range field
.input-range {

  .col-xs-6 {
    padding: 0;
  }

  .first {
    @include border-radius($rad 0 0 $rad);
    padding: 0 $spacing;
    border-right: none !important;
    text-align: center;
  }

  .second {
    @include border-radius(0 $rad $rad 0);
    border-left: none !important;
    padding: 0;
    text-align: center;

  }

}


  &.expandable {

    .rt-td,
    .rt-th {
      &:nth-child(1) {
        display: none !important;
      }
    }
  }

  // Every row
  .rt-thead {
    .rt-th {
      padding: 0;
      padding-right: $padding;
    }
  }

  // Top headerGroups
  .rt-thead.-headerGroups {

    .rt-th {
      // border-bottom: solid 1px $dark2;
      // background: $light3;
      font-size: $font-large;

      text-align: left;
      line-height: normal;
      padding: $padding;
      font-weight: 900;
      color: $dark5;




      //  border-right: solid 1px lighten($dark2, 10);


      &:last-child {
        border-right: none;
      }
    }

  }


  // Next header
  .rt-thead.-header {
    @media (max-width: $size-mobile) {
      display: none;
    }


    &:active {
      //  background: transparent;
    }

    &:focus {
      //   background: $dark1;
    }


    //   border: solid $rad $light2;
    .rt-th {
      font-size: $font-normal;
      color: $dark3;
      border: none;
      font-weight: 900;
      border-radius: $rad;
      text-align: center;
      background: darken($light2, 3);
      padding: $spacing 0;
      margin: 0;
      @include border-radius(0);
      border-right: none;


      &:hover {
        background: $light3;
      }

      @media (max-width: $size-mobile) {
        border: none !important;
        padding: $spacing;
        background: transparent;
        padding: $spacing; //
        line-height: 1;
        overflow: visible;
      }

      // border-right: solid 1px $light3 !important;

      &.rthfc-th-fixed {
        background: darken($light2, 3);


        &:hover {
    //      background: $light3;
        }

        @media (max-width: $size-mobile) {
          background: transparent;
          border-right: none !important; // solid 1px darken($light2, 3) !important;

          line-height: 1;
        }
      }



      &.-sort-asc {
        box-shadow: none;

        &::after {
          //content: '▴';
          right: $spacing;
          color: $pink;
          position: absolute;
          top: 0;
        }

        //  border-bottom: solid 3px $green1;
      }

      &.-sort-desc {
        box-shadow: none;

        &::after {
          content: '▾';
          right: $spacing;
          color: $pink;
          position: absolute;
          top: 0;

        }

        //   border-top: solid 3px $blue1;
      }


      @media (max-width: $size-mobile) {
        font-size: $font-normal;
        ;
      }
    }
  }

  &.double-header .rt-thead.-header {
    .rt-th {
      font-weight: 400;
    }

  }

  // Filters
  .rt-thead.-filters {
    //  position: absolute;
    background: darken($light2, 3);
    border: none;
    border-right: none;
    border-left: none;
    border-bottom: solid 1px $light3;
    overflow: visible;
    box-shadow: 0 5px 5px rgba(182, 182, 182, 0.25);
    top: 0;
    padding-bottom: 0;


    .rt-th {

      @media (max-width: $size-mobile) {
        padding: $spacing;
        background: $light1;
      }

      overflow: visible;
      padding: 0 $padding;
      padding-bottom: $spacing;
      //border-right: solid 1px $light3;
      border-right: none;


      &.rthfc-th-fixed {
        background: darken($light2, 3);

        @media (max-width: $size-mobile) {
          border-right: solid 1px $light3 !important;
          border-right: none !important;
          background: $light1;
        }
      }
    }

  }

  // NOrmal row
  &.-striped .rt-tr.-odd {
    //  background: rgba(205, 225, 255, 0.07);
    background: $light3;

    background: transparent;
  }


  .rt-tbody {

    -webkit-box-shadow: inset -8px 0px 12px 0px rgba(0, 0, 0, 0.37);
    box-shadow: inset -8px 0px 12px 0px rgba(0, 0, 0, 0.37);
  }

  // Normal row
  .rt-tbody {

    .rt-tr-group {
      border-bottom: none;
      background: $light1;

      text-align: center;



      @media (max-width: $size-mobile) {
        border-bottom: solid 1px $light3;
      }

      &:hover {
        cursor: pointer;


        @media (max-width: $size-mobile) {
          background: darken($light1, 3);
          ; //darken($light1, 3);
        }

        .rt-td {
          &.rthfc-td-fixed {
            background: darken($light1, 3);
            ; //darken($light1, 3);

          }
        }
      }
    }


    .rt-th,
    .rt-tr {


      line-height: $desktop-row;
      font-size: $font-largish;
      background: transparent;
      padding: 0;
      border-bottom: solid 1px lighten($light2, 3);



      @media (max-width: $size-mobile) {
        // border-bottom: solid 1px $light3;
        line-height: $mobile-row;
        padding: 0;
      }


    }

    .rt-td {

      font-size: $font-normal;
      padding: 0;
      border: none;
      background: transparent;
      .btn-link{
        max-height: none;
        line-height: unset;
      }

      &.align-left{
        >a{
          justify-content: start;
        }
      }

      >a {
        display: flex;
        width: 100%;
        height: 100%;
        font-weight: normal;
        justify-content: center;

        
        &:hover {
          text-decoration: none;
          color: $dark5;

          .position-container h3 {
            text-decoration: underline !important;
          }
        }

        //      &:focus,
        &:active {
          .position-container h3 {
            // text-decoration: none !important;
          }

        }


      }

      @media (max-width: $size-mobile) {
        line-height: $padding;
        font-size: $font-normal;
        padding: $spacing $spacing;
        white-space: normal;
      }

      &:hover {
        background: darken($light1, 3);
      }

      &.rthfc-td-fixed {
        background: $light1;

        //  border-right: solid 1px $light2;
        @media (max-width: $size-mobile) {
          border-right: none; //solid 1px $light3 !important;
        }
      }


      &.align-left {
        padding-left: $padding + 1px;
      }


      .title-container {
        padding-top: $spacing + 1px;
        line-height: $desktop-row - $spacing;


        @media (max-width: $size-mobile) {
          line-height: 1;
          padding: 0;
        }


        .image-container {
          display: inline-block;
          width: $padding * 1.5;
          margin-right: $rad;

          @media (max-width: $size-mobile) {
            display: none;
          }
        }

        .offer-container {
          display: block;
          font-size: $font-normal;
          width: auto;
          color: $pink;
          line-height: $spacing * 1.5;

          margin: 0;

          &.four-days {
            color: $green1;
          }

          @media (max-width: $size-mobile) {
            .fa-thumbs-up {
              display: none;
            }
          }

          @media (min-width: $size-mobile) {
            // display: none;
            display: inline-block;
            margin-left: $spacing;

          }

        }

        .position-container {
          display: inline-block;
          padding-left: $spacing;
          width: calc(100% - #{$padding * 1.5});

          @media (max-width: $size-mobile) {
            padding-left: 0;
            width: 100%;
          }

          h3,
          p {
            display: block;
            text-align: left;
            color: $dark5;
            font-size: $font-normal;
          }

          h3 {
            padding-top: $rad;
            font-weight: 700;
            line-height: $spacing * 1.5;
            margin-bottom: 0;
            white-space: normal;

            @media (max-width: $size-mobile) {
              padding-right: $spacing;
              font-size: $font-normal;
            }

          }

          p {
            font-size: $font-normal;
            line-height: $padding;
            padding-bottom: $rad;

            @media(max-width: $size-mobile) {
              line-height: $padding * 1.25;
            }

          }
        }

      }

      &.subtle {
        color: $dark3;
        font-size: $font-small;
      }


      .days-ago {
        font-size: $font-normal;
        color: $dark1;

        .small-date {
          padding-left: $rad;
          font-size: $font-small;
          font-weight: 400;
          color: $dark4;
          font-style: italic;

          @media(max-width: $size-mobile) {
            display: none;
          }

        }
      }

      .grid-img {
        width: 100%;
        padding: 0;
        margin-top: -$padding;
        @include border-radius($rad);

        &.tiny {
          height: $spacing * 1.5;
          margin-top: -3px; //-$rad;
          margin-right: 2px;
          width: $spacing * 1.5;

          @media(min-width: $size-mobile) {
            display: none;
          }
        }

      }
    }
  }



  // Scrollbars

  &:hover {
    ::-webkit-scrollbar-thumb {
      background: $light4;
    }
  }

  ::-webkit-scrollbar {
    height: $padding + $rad;
    width: $spacing + $rad;
  }

  ::-webkit-scrollbar-track {
    border: solid 1px $light4;
    border-left: none;
    border-right: none;
    border-bottom: none;
    background-color: $light1;

  }

  ::-webkit-scrollbar-thumb {
    @include border-radius($padding);
    background: $dark1;

    &:hover {
      background: $dark2;
    }


  }


  input,
  select {
    line-height: $control-height;
    padding: 0 $spacing !important;
    font-size: $font-normal;
    //  border: solid 1px $dark1;
    @media (max-width: $size-mobile) {
      line-height: $padding * 2;
      height: $padding * 2;
    }
  }


  input[type=number] {
    padding: 0 $rad / 2 0 $rad / 2;
    margin-right: 1px;


    @media (max-width: $size-mobile) {
      padding: 0 $rad / 2;

    }


    &.first {
      font-size: $font-tiny;
      padding-right: $rad;
      border-right: solid 1px lighten($light2, 4) !important;
    }

    &.second {
      padding-left: $rad;
      font-size: $font-tiny;
      text-align: center;

    }


  }



  // Pagination
  .-pagination {
    box-shadow: none;
    background: transparent;
    border-top: none;
    padding: $spacing;

    @media (max-width: $size-mobile) {
      padding: 0;
      padding-top: $padding;
    }

    .-previous .-btn {
      float: left;

      &::before {
        content: '← ';

        @media (max-width: $size-mobile) {
          display: none;
        }
      }
    }

    .-next .-btn {
      float: right;


      &::after {
        content: ' →';

        @media (max-width: $size-mobile) {
          display: none;
        }
      }
    }

    .-btn {
      max-width: 120px;
      background: $blue2;
      height: 100%;
      font-size: $font-largish;
      line-height: $control-height;
      color: $light1;
      padding: 0;

      @include border-radius($rad);

      @media (max-width: $size-mobile) {
        line-height: $control-height * 1.25;
      }

      &[disabled] {
        opacity: .3;
        cursor: default;
      }

      &:not([disabled]):hover {
        border: none;
        background: $green1;
      }
    }

    .-center {
      color: $dark5;
      font-size: $font-normal;
    }

    .-pageInfo {
      display: none; //inline-block;
      margin: 0 $spacing;
      white-space: nowrap;
    }

    .-pageJump {
      display: inline-block;

      input {
        width: 60px;
        text-align: center;
        color: $dark5;
        border-color: $dark1;
        padding: 0 $rad;
      }
    }

    .-pageSizeOptions {
      select {
        padding: 0 $rad;
      }
    }
  }

  // No data
  .rt-noData {
    color: $dark3;
    background: transparent;
    font-size: $font-largish;
    pointer-events: all;
  }


}