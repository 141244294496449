// React select
// Actual react-select input
.react-select__control {
    font-size: $font-large * 0.75;
    box-shadow: none !important;
    background: $light1; //$light1;// lighten($light2, 10);
    border: solid 1px darken($light4, 3) !important;

    &.react-select__control--is-focused {
        border-color: $green1;
    }



    &:hover {
        cursor: pointer !important;
        background: lighten($light2, 3);
    }


    .react-select-multi {
     //   padding: $spacing 0 $spacing $spacing;
        border: none;
        max-width: calc(100% - 80px);




        .react-select-multi-values {
            //      background-color: red;
            // Elipsis
            padding: $spacing $padding;
            line-height: 1.35;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            @media (max-width: $size-mobile) {
                line-height: 2;
            }
    
        }

        .react-select-multi-input {
            //   background: yellow;
            position: absolute;
            top: $rad;
            left: $spacing;

            .react-select__input-container{
                .react-select__input{
                    box-shadow: none;;
                }
            }
        }
    }

    .react-select__value-container {
        //height: $padding * 4;
        //padding-left: $padding * 2.25;
        padding: $padding * .75 $spacing;
        font-size: $font-normal * 1.15;
        line-height: 1.3;



        .react-select__input-container {
            padding: 0;
            margin: 0;


        }

        .react-select__multi-value__label {
            font-size: $font-normal;
        }

        .number {
            color: $green1;
            font-weight: 900;
            font-size: $font-small;
            padding-left: $rad;
            display: none;
        }

        @media (max-width: $size-mobile) {
            height: $padding * 3.5;

        }


        .react-select__single-value {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $dark5;
            font-size: $font-largish;

            .filter-type {
                display: none;
            }
        }

        .react-select__placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */

            overflow: hidden;
            white-space: nowrap;

            text-overflow: ellipsis;
        }

        .react-select__input {
            box-shadow: none;
            @include border-radius(0);



        }

    }

    .react-select__placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $dark1; //lighten($dark1, 15);
    }

    // Right buttons
    .react-select__indicators {

        .react-select__dropdown-indicator {
            padding-right: $spacing * 1.5;

        }

        // .react-select__clear-indicator,
        .react-select__indicator-separator {
            display: none;
        }

    }

}

// Dropdown menu
.react-select__menu {
    z-index: 1000000;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
      
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $dark1;
        box-shadow: 0 0 1px rgba(255, 255, 255, .4);
      }

    .react-select__menu-list {
        padding: 0;
       // border: solid 1px $light3;
        @include box-shadow-lg();
        border: none;
        @include border-radius($rad);

        .react-select__option {
            display: block;
            font-size: $font-normal;
            margin: 0;

            padding: $rad * 2.5 $rad * 2;

    

            &.react-select__option--is-selected {
                background: rgba(lighten($green1, 8), 0.25);
                color: $dark5;
            }

            input {
                display: inline-block;
                margin: 0;
                padding: 0;
                text-align: center;
                vertical-align: middle;
                line-height: 1;
            }

      

            input[type='checkbox']{
                border: solid 1px red !important;
            }

            label {
                margin: 0;
                padding: 0;
                font-size: $font-normal;
                width: calc(100% - #{$padding * 4});
                display: inline-block;
                padding-left: $spacing;
                ;

            }

            &:hover {
                cursor: pointer !important;
            }

            .number {
                color: $dark1;
                position: absolute;
                right: $padding;
                margin-left: $padding;
                font-size: $font-small;
            }

        }
    }
}